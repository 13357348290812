
















import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Modals',
  components: {
    BrandLinkModal: () => import(/* webpackChunkName: "brand-link-modal" */ '~/components/layout/Modals/BrandLinkModal.vue'),
    AfterAddToCartModal: () => import(/* webpackChunkName: "after-add-to-cart-modal" */ './AfterAddToCartModal/AfterAddToCartModal.vue'),
    InfoModal: () => import(/* webpackChunkName: "info-modal" */ './InfoModal.vue'),
    LightBoxImageModal: () => import(/* webpackChunkName: "lightbox-image-modal" */ './LightBoxImageModal.vue'),
    ManufacturerModal: () => import(/* webpackChunkName: "manufacturer-modal" */ './ManufacturerModal.vue'),
    NewVisibilityModal: () => import(/* webpackChunkName: "new-visibility-modal" */ './NewVisibilityModal/NewVisibilityModal.vue'),
    OutOfStockModal: () => import(/* webpackChunkName: "out-of-stock-modal" */ './OutOfStockModal/OutOfStockModal.vue'),
    ProductPackModal: () => import(/* webpackChunkName: "product-pack-modal" */ './ProductPackModal.vue'),
    ProtectionModal: () => import(/* webpackChunkName: "protection-modal" */ './ProtectionModal/ProtectionModal.vue'),
    SwitchAndSellModal: () => import(/* webpackChunkName: "switch-and-sell-modal" */ './SwitchAndSellModal/SwitchAndSellModal.vue'),
    WarrantyModal: () => import(/* webpackChunkName: "warranty-modal" */ './WarrantyModal/WarrantyModal.vue'),
  },
});
